import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Brochure from "../../static/media/providers/maternit21-brochure.pdf";

// eslint-disable-next-line
export const PreparePageTemplate = ({
  title,
  helmet,
  content,
  contentComponent,
  instructions,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content content-border">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <hr />
            <PostContent content={content} />
            {instructions && instructions.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4 className="has-text-centered">
                  Specific Test Instructions
                </h4>
                <br />
                <div className="columns is-multiline is-desktop">
                  {instructions.map((instruction) => (
                    <div
                      key={instruction.test + `instruction`}
                      className="has-text-centered column is-6-desktop"
                    >
                      <div className="simple-border">
                        <h4>{instruction.test}</h4>
                        <p>{instruction.description}</p>
                        <a
                          href={instruction.document.publicURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="has-text-primary"
                        >
                          <strong>View instructions for this test</strong>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

PreparePageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  instructions: PropTypes.arrayOf(
    PropTypes.shape({
      test: PropTypes.string,
      description: PropTypes.string,
      document: PropTypes.object,
    })
  ),
};

const Prepare = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <PreparePageTemplate
        helmet={
          <Helmet titleTemplate="%s | Pathlab">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        content={post.html}
        contentComponent={HTMLContent}
        instructions={post.frontmatter.instructions}
      />
    </Layout>
  );
};

Prepare.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Prepare;

export const pageQuery = graphql`
  query PrepareByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        instructions {
          test
          description
          document {
            publicURL
          }
        }
      }
    }
  }
`;
